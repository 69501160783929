<template>
<div>
   
  <div class="card" v-if="packages">
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bolder fs-3 mb-1">Kullanıcının Sahip olduğu paketler</span>

        <span class="text-muted mt-1 fw-bold fs-7">Toplam <b>{{recordcount}}</b> adet paket</span>
      </h3>
    </div>
    <!--end::Header-->

    
    <div class="card-body py-3">
      <!--begin::Table container-->
      <div class="table-responsive">

          <a v-if="isAdmin" href="#new_package" data-bs-target="#new_package" class="btn btn-success btn-sm mb-10" data-bs-toggle="modal">Yeni paket tanımla</a>

           <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
               <thead>
                   <tr class="fw-bold">
                       <th>Paket</th>
                       <th>Süre/Aylık</th>                       
                       <th>Başlangıç Tarihi</th>
                       <th>Bitiş Tarihi</th>
                       <th>İptal Tarihi</th>
                       <th>Kalan Gün</th>
                       <th>İşlemler</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for="(item, i) in packages" :key="i">
                       <td>{{item.sPackage}}</td>
                       <td>{{item.duration}}</td>                       
                       <td>{{moment(item.startDate).format('DD MMMM YYYY HH:MM')}}</td>
                       <td>{{moment(item.expireDate).format('DD MMMM YYYY HH:MM')}}</td>
                       <td>{{item.cancelDate ? moment(item.cancelDate).format('DD MMMM YYYY HH:MM') : ''}}</td>
                       <td>{{!item.cancelDate ? leftDay(item.expireDate) : '0'}}</td>
                       <td><a v-if="isAdmin" @click.prevent="removePackage(item)" class="btn btn-danger btn-sm" href="">sil</a></td>
                       
                         
                   </tr>
               </tbody>
           </table>

           <paging 
            v-if="recordcount>0" 
            :page="page" 
            :pagelength="pagelength" 
            :recordcount="recordcount" 
            @next="next" 
            @prev="prev"
            @gotoPage="gotoPage($event)"
            />

      </div>
    </div>
  </div>

   <div class="modal fade" ref="modal" tabindex="-1" id="new_package">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title">
          <span class="text-danger fw-bolder">
            Paket seçin
          </span>
          </h5>

        <!--begin::Close-->
        <div
          class="btn btn-icon btn-sm btn-active-light-primary ms-2"
          data-bs-dismiss="modal"
          aria-label="Close"
        >
          <span class="svg-icon svg-icon-2x"></span>
        </div>
        <!--end::Close-->
      </div>

      <div class="modal-body" v-if="availablePackages" >
         
         
         <div class="row mb-2" v-for="(packageItem) in availablePackages" :key="packageItem.packageId">
           <label class="pe-10 ps-10 pb-2 pt-2 hover-bg-primary d-flex align-items-center border border-radius">
             <input type="radio" class="me-5" name="packageId" v-model="packageId" :value="packageItem.packageId">
             <span>{{packageItem.packageName}}</span>
           </label>
         </div>
      </div>

      <div class="modal-footer">
        <button type="button" data-bs-dismiss="modal" class="btn btn-light">Kapat</button>
        <button type="submit" @click.prevent="setPackage()" class="btn btn-primary">
          Kaydet
        </button>
      </div>
    </div>
  </div>
</div>

</div>
</template>

<script>

import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import labels from "@/core/config/labels.js"
import Swal from "sweetalert2/dist/sweetalert2.js";
import moment from 'moment'
import api from '@/core/services/ApiService'
import Paging from '@/components/BD/Paging.vue';


export default {
  components: { Paging },
  name: "Gorusmeler",
  props:['profile','isAdmin'],
  data(){
      return {
          packages:null,
          recordcount:0,
          paymentTypes:labels.paymentTypes,
          callStatusses:labels.callStatusses,
          pagelength:30,
          page:1,
          availablePackages:null,
          packageId:null,
      }
  },
  watch:{
      page:function(){
        this.getPackages();
      }
    },

  methods:{

      removePackage(selectedPackage){
        

        Swal.fire({
          text:'Kullanıcının paketini iptal etmek istediğinize emin misiniz',
          showCancelButton:true,
          icon:'warning',
          cancelButtonText:'Hayır',
          confirmButtonText:'Evet',
        }).then((res)=>{
          if(res.isConfirmed){
            api.get('/Package/CancelPackage/'+selectedPackage.purchaseId).then((res)=>{
              document.location.reload();
            })
          }  
        })
        
        

      },


      leftDay(expireDate){
          let today = new Date();
          return moment(expireDate).diff(today,'days');
      },
      moment(date){
          return moment(date)
      },

      next(){
        this.page++;
      },

      prev(){
        if(this.page>1){
          this.page--;
        }
      },

      gotoPage(pageNo){
        this.$router.push('?page='+pageNo);        
        this.page = pageNo;
      },

      setPackage(){

         Swal.fire({
          text:'Paketi kullanıcıya eklemek istediğinize emin misiniz?',
          showCancelButton:true,
          icon:'warning',
          cancelButtonText:'Hayır',
          confirmButtonText:'Evet',
        }).then((res)=>{
          if(res.isConfirmed){
           api.get('/Package/SetPackage/'+this.packageId+'/'+this.profile.userId+'/QLGlsz6mlHDD37K9rtUckB').then((res)=>{
            Swal.fire({
              icon:'success',
              title:'Başarılı',
              text:'Paket eklendi'
            }).then((res)=>{
              document.location.reload();
            })
          });
          }  
        })
      
      
    },

      getPackages(){
         if(this.profile){
           let url = '/Package/ListPackage/User/'+this.profile.userId+"/"+this.pagelength+'/'+this.page;

             api.get(url).then((res)=>{
                this.packages = res.data.userPurchases;
                this.recordcount = res.data.rowCount;
            });
         }
          
      }


  },
  mounted(){
      setCurrentPageBreadcrumbs("Paketler", ["Kullanıcılar", this.profile.name+' '+this.profile.surname]);

       api.get('/Package/ListPackage/AC/JJ/0').then((res)=>{
        this.availablePackages = res.data;
      })
      this.getPackages();
    
  }
  
}
</script>
